
import axios from '../../http/axios/index.js'

export default {
  SET_BEARER (state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  },
  UPDATE_ROLES (state, roles) {
    this._vm.$acl.change(roles)
  }
}
