import navbarFr from './fr/navbar.json'
import administrationFr from './fr/administration.json'
import loginFr from './fr/login.json'
import pricingFr from './fr/pricing.json'
import commentFr from './fr/comment.json'
import auditFr from './fr/activity.json'
import layoutFr from './fr/layout.json'
import translationFr from './fr/translation.json'
import galleryFr from './fr/gallery.json'
import stockFr from './fr/stock.json'

export default {
  fr: {
    ...navbarFr,
    ...administrationFr,
    ...loginFr,
    ...pricingFr,
    ...auditFr,
    ...commentFr,
    ...layoutFr,
    ...translationFr,
    ...galleryFr,
    ...stockFr
  }
}
