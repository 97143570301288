import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'
import router from '@/router'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  init () {
    if (!axios.defaults.headers.common['Authorization'] && localStorage.getItem('accessToken') !== null) {
      store.commit('auth/SET_BEARER', localStorage.getItem('accessToken'))
    }

    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      if (response && response.status === 401) {
        if (originalRequest.url === '/authentication') {
          return Promise.reject(error)
        }

        if (
          localStorage.getItem('refreshToken') === null
          || originalRequest.url === '/authentication/refresh'
        ) {
          router.push({name: 'page-logout'})
          return Promise.reject(error)
        }

        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessToken')
            .then((response) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(response.data.token)
              localStorage.setItem('accessToken', response.data.token)
              localStorage.setItem('refreshToken', response.data.refresh_token)
              store.commit('auth/SET_BEARER', response.data.token)
            })
        }

        return new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            resolve(axios(originalRequest))
          })
        })
      }

      if (response && response.status === 403) {
        router.push({name: 'page-not-authorized'})
      }

      return Promise.reject(error)
    })
  },
  login (username, password) {
    return axios.post('/authentication', {
      username,
      password
    })
  },
  // registerUser (name, username, password) {
  //   return axios.post('/api/auth/register', {
  //     displayName: name,
  //     username,
  //     password
  //   })
  // },
  refreshToken () {
    return axios.post('/authentication/refresh', {'refresh_token': localStorage.getItem('refreshToken')})
  }
}
