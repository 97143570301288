import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'
import moduleAuthGetters from '../store/auth/moduleAuthGetters'

Vue.use(AclInstaller)

const initialRole = 'IS_AUTHENTICATED_ANONYMOUSLY'

export default new AclCreate({
  initial  : initialRole,
  notfound: { name: 'page-not-authorized' },
  router,
  acceptLocalRules : true,
  globalRules: {
    superadmin: new AclRule('ROLE_SUPER_ADMIN').or('ROLE_ADMIN').generate(),
    admin: new AclRule('ROLE_ADMIN').generate(),
    galleryAdmin: new AclRule('ROLE_GALLERY_ADMIN').generate(),
    galleryUpload: new AclRule('ROLE_GALLERY_ADMIN').or('ROLE_GALLERY_MARKETING').generate(),
    galleryExport: new AclRule('ROLE_GALLERY_ADMIN').or('ROLE_GALLERY_MARKETING').or('ROLE_GALLERY_INTERN').generate(),
    gallery: new AclRule('ROLE_ABSTRACT_GALLERY').generate(),
    pricingEditor: new AclRule('ROLE_PRICING_PRICING_EDITOR').generate(),
    pricing: new AclRule('ROLE_PRICING').or('ROLE_PRICING_PRICING_EDITOR').or('ROLE_PRICING_SHEET_EDITOR').or('ROLE_PRICING_SHEET_VALIDATOR').or('ROLE_PRICING_PRICING_VALIDATOR').or('ROLE_PRICING_PUBLISHER_VALIDATOR').or('ROLE_PRICING_ADMIN').or('ROLE_ADMIN').generate(),
    sheet: new AclRule('ROLE_SHEET').or('ROLE_PRICING_SHEET_EDITOR').or('ROLE_PRICING_SHEET_VALIDATOR').or('ROLE_PRICING_PUBLISHER_VALIDATOR').or('ROLE_ADMIN').generate(),
    user: new AclRule('ROLE_USER').or('ROLE_ADMIN').generate(),
    guest: new AclRule('IS_AUTHENTICATED_ANONYMOUSLY').or('ROLE_USER').generate(),
    stockReader: new AclRule('ROLE_STOCK_READ').generate(),
    stockEnovos: new AclRule('ROLE_STOCK_ENOVOS').or('ROLE_STOCK_ADMIN').generate(),
    stockEncevo: new AclRule('ROLE_STOCK_ENCEVO').or('ROLE_STOCK_ADMIN').generate(),
    stockReadEncevo: new AclRule('ROLE_STOCK_ENCEVO').generate(),
    stockAdmin: new AclRule('ROLE_STOCK_ADMIN').or('ROLE_ADMIN').generate(),
    stockAdminEnovos: new AclRule('ROLE_STOCK_ADMIN').or('ROLE_STOCK_ENOVOS').generate(),
    stock: new AclRule('ROLE_STOCK_ADMIN').or('ROLE_STOCK_ENOVOS').or('ROLE_STOCK_ENCEVO').or('ROLE_STOCK_READ').generate(),
    stockEdit: new AclRule('ROLE_STOCK_ADMIN').or('ROLE_STOCK_ENOVOS').or('ROLE_STOCK_ENCEVO').or('ROLE_ADMIN').generate(),
    stockMateriel: new AclRule('ROLE_STOCK_ADMIN').or('ROLE_STOCK_ENOVOS').or('ROLE_STOCK_READ').generate()

  },
  middleware: async acl => {
    const userInfo = moduleAuthGetters.authUser()
    if (userInfo && userInfo.roles) {
      acl.change(Object.values(userInfo.roles), { name: 'page-not-authorized' })
    } else {
      acl.change(initialRole, { name: 'page-login' })
    }
  }
})

