/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      {title: 'Home',   i18n: 'navbar.menu.dashboard',  url: { name: 'home'},          icon: 'HomeIcon', is_bookmarked: false},
      {title: 'Tarifs', i18n: 'navbar.menu.pricing',    url: { name: 'pricing-list' }, icon: 'FileIcon', is_bookmarked: false},
      {title: 'Traductions', i18n: 'navbar.menu.translations',    url: { name: 'translations' }, icon: 'FlagIcon', is_bookmarked: false},
    ]
  }
}
/* eslint-enable */
