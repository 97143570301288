// axios
import axios from 'axios'

// eslint-disable-next-line no-undef
const baseURL = config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL

export default axios.create({
  baseURL
  // You can add your headers here
})
